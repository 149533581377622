<template>
  <div class="my-8">
    <TabNav :tabLabels="['Employee Data', 'Attendance']" :selected="selected" @selected="setSelected" :tabNavGap="2">
      <Tab :isSelected="selected === 'Employee Data'">
        <button
          class="font-bold rounded-md bg-yellow-primary text-white border-yellow-primary hover:bg-yellow-300 focus:outline-none text-xs py-2 px-6 absolute -top-10 right-4"
          @click="toEditEmployee(idForm)"
        >
          Edit
        </button>
        <div class="flex flex-col gap-6">
          <TextField type="text" label="Name" placeholder="Enter Name" :borderEnabled="true" v-model="options.name" :error="errors['name']" disabled />
          <TextField type="text" label="Email" placeholder="Enter Email" :borderEnabled="true" v-model="options.email" :error="errors['email']" disabled />
          <TextField type="date" label="First Joined" placeholder="First Joined" :borderEnabled="true" v-model="options.firstJoinDate" :error="errors['firstJoinDate']" disabled />
        </div>
      </Tab>
      <Tab :isSelected="selected === 'Attendance'">
        <div class="flex items-stretch">
          <div class="flex flex-col gap-6 pr-6 w-1/2 shadow-border-r flex-shrink-0">
            <div class="flex items-center">
              <div class="mr-4">
                <p class="text-sm my-2 text-gray-500">Start date</p>
                <TextField type="date" v-model="options.startDate" borderEnabled placeholder="Select Date" class="w-36" />
              </div>
              <span class="mt-8 -ml-2">-</span>
              <div class="ml-2">
                <p class="text-sm my-2 text-gray-500">End date</p>
                <TextField type="date" v-model="options.endDate" borderEnabled placeholder="Select Date" class="w-36" />
              </div>
              <div class="flex justify-start">
                <button class="w-20 p-2 mx-3 mt-8 font-semibold inline bg-yellow text-white text-center rounded-lg" @click="onSearch()">Apply</button>
                <button class="p-2 bg-yellow-secondary mt-8 rounded-lg inline" @click="resetFilter()"><img src="../../assets/images/refresh.png" alt="" /></button>
                <!-- <p class="text-yellow flex items-center gap-1 cursor-pointer font-semibold w-36 mt-7 ml-2" @click="resetFilter()"><Trash />Reset Filter</p> -->
              </div>
            </div>
            <template v-if="selectedTab === 'Attendance'">
              <vue-good-table
                mode="remote"
                :pagination-options="{
                  enabled: true,
                  mode: 'pages',
                  perPage: perPage,
                  position: 'bottom',
                  perPageDropdown: [5, 7, 10],
                  dropdownAllowAll: false,
                  nextLabel: 'next',
                  prevLabel: 'prev',
                  rowsPerPageLabel: 'Rows per page',
                  ofLabel: 'of',
                  pageLabel: 'page', // for 'pages' mode
                  allLabel: 'All'
                }"
                :totalRows="totalRecords"
                :rows="data"
                :columns="columns2"
                @on-page-change="onPageChange"
                @on-per-page-change="onPageChange"
                :row-style-class="rowStyleClassFn"
              >
                <template slot="table-row" slot-scope="props">
                  <div v-if="props.column.field === 'checkIn'">
                    <span style="display: block; font-size: 14px; color: #333">
                      {{ formatDate(props.formattedRow[props.column.field]).time ? formatDate(props.formattedRow[props.column.field]).time : '-' }}
                    </span>
                  </div>
                  <div v-else-if="props.column.field === 'checkOut'">
                    <span style="display: block; white-space: nowrap">
                      {{ formatDate(props.formattedRow[props.column.field]).time ? formatDate(props.formattedRow[props.column.field]).time : '-' }}
                    </span>
                  </div>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </template>
          </div>
          <div class="p-6 w-1/2 flex-shrink-0">
            <div class="flex justify-between">
              <p class="font-bold -mt-8">Attendance Summary</p>
              <p class="-mt-8">{{ label }}</p>
            </div>
            <div class="my-4 grid grid-cols-2 max-h-screen">
              <div class="relative" v-if="pieData ? pieData : false">
                <pie-chart :data="pieData" :options="{}"></pie-chart>
              </div>
              <div class="flex justify-center items-center" v-else>
                <p class="mt-24 ml-20">Data not found</p>
              </div>
              <div class="pl-6">
                <div class="flex items-center my-2" v-for="(data, idx) in pieData" :key="idx">
                  <div :style="`background: ${data.color}`" class="mr-2 w-7 h-7 rounded-sm"></div>
                  <div>
                    <p>{{ data.title }}</p>
                    <p class="text-lg font-bold">{{ data.count }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Tab>
    </TabNav>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
